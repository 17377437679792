/**
 * Keep track of all Kameleoon experiments.
 * Remember to remove related code when the experiment is done.
 */
export enum ActiveExperiments {
  MobileTimetableOnlyUK = 'MOBILE_TIMETABLE_ONLY_UK',
  MobileTimetableOnlyFR = 'MOBILE_TIMETABLE_ONLY_FR',

  DiscountBannerPCT = 'DISCOUNT_BANNER_PCT_V2',

  EWOShockPricingUK = 'EWO_SHOCK_PRICING_UK',
  EWOShockPricingFR = 'EWO_SHOCK_PRICING_FR',

  ServicingQuotesPriceMatchPromiseUK = 'SERVICING_QUOTES_PRICE_MATCH_PROMISE_UK',

  KlarnaFakeDoorInCheckoutFlowUK = 'KLARNA_FAKE_DOOR_IN_CHECKOUT_FLOW_UK',

  /**
   * Active campaign popup form.
   */
  ACForm10Off = 'AC_FORM_10_OFF',

  RevisionConstructeurFR = 'REVISION_CONSTRUCTEUR_FR',

  CustomerCndMapViewUK = 'CUSTOMER_CND_MAP_VIEW_UK',
  CustomerCndMapViewFR = 'CUSTOMER_CND_MAP_VIEW_FR',

  liquidCrossSellFlowUK = 'LIQUID_CROSS_SELL_FLOW_UK',
  liquidCrossSellFlowFR = 'LIQUID_CROSS_SELL_FLOW_FR',

  /**
   * Show banners on Reference, hide on B variant
   */
  LongTailPagePromoBannerUK = 'LONGTAIL_PAGE_PROMO_BANNER_UK',

  SchedulePagePricingUK = 'SCHEDULE_PAGE_PRICING_UK',
  SchedulePagePricingFR = 'SCHEDULE_PAGE_PRICING_FR',

  EWOProposedPageImprovementsUK = 'EWO_PROPOSED_PAGE_IMPROVEMENTS_UK',
  EWOProposedPageImprovementsFR = 'EWO_PROPOSED_PAGE_IMPROVEMENTS_FR',

  EWOPaymentPageImprovementsUK = 'EWO_PAYMENT_PAGE_IMPROVEMENTS_UK',
  EWOPaymentPageImprovementsFR = 'EWO_PAYMENT_PAGE_IMPROVEMENTS_FR',

  HomepageHeroNavServicesUK = 'HOMEPAGE_HERO_NAV_SERVICES_UK',

  HomepageServicesComponentFR = 'HOMEPAGE_SERVICES_COMPONENT_FR',

  ServicePagePriceTagUK = 'SERVICE_PAGE_PRICE_TAG_UK',
  ServicePagePriceTagFR = 'SERVICE_PAGE_PRICE_TAG_FR',
}
